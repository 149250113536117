<template>
    <div>
        <div class="modal fade" id="PurchasingPaymentInvoiceCreateModal" tabindex="-1" aria-labelledby="PurchasingPaymentInvoiceCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PurchasingPaymentInvoiceCreateModalLabel" class="font-weight-bold">Add Purchasing Payment Invoice</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <purchasing-payment-invoice-create-grid ref="grid" :key="gridReload" :createClick="createClick"/>
                        <br>
                        <CButton type="button" @click="createClick()" id="saveButton" color="success" style="float: right;"> Create </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>    
import purchasingPaymentInvoiceCreateGrid from '../Grid/PurchasingPaymentInvoiceCreateGrid.vue';
import purchasingPaymentInvoiceServices from '../Script/PurchasingPaymentInvoiceServices.js';

export default {
    name: 'PurchasingPaymentInvoiceCreateForm',
    components: {
        'purchasing-payment-invoice-create-grid': purchasingPaymentInvoiceCreateGrid,
    },
    mounted:  function () {
    },
    data: function () {
        return {
           gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.reload();
            window.$('#PurchasingPaymentInvoiceCreateModal').modal('show');
        },
        createClick(){
            if (this.$refs.grid.hf_ID_Filter == '') {
                this.$swal("Info", "Harap untuk memilih invoice sebelum menekan tombol Create", "info");
            }
            else {
                var validate = purchasingPaymentInvoiceServices.validateDuplicateSelectedData(this.$refs.grid.hf_ID_Filter);
                
                if(validate == 'not match'){
                    this.$swal("Info", "Harap untuk memilih invoice dengan 'Nama Supplier' dan 'Mata Uang' yang sama", "info");
                }else{
                    var checkall = 'false';
                    var selectedId = JSON.stringify(validate);
                    window.$('#PurchasingPaymentInvoiceCreateModal').modal('hide');
                    this.$router.push({ name: 'Purchasing Payment Invoice Form', params: { formtype:'Add', id:selectedId, view:'new' } });
                }
            }
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>
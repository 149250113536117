<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import purchasingPaymentInvoiceServices from '../Script/PurchasingPaymentInvoiceServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurchasingPaymentnvoiceCreateGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['createClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_form_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { query: purchasingPaymentInvoiceServices.readPayablePurchasingInvoiceQuery()};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if (response.data.GetPurchasingPayableInvoiceList == null) {
                                return [];
                            }
                            else {
                                return response.data.GetPurchasingPayableInvoiceList;
                            }
                        },
                        total: function (response) {
                            if (response.data.GetPurchasingPayableInvoiceList == null) {
                                return 0;
                            }
                            else {
                                return response.data.GetPurchasingPayableInvoiceList.length;
                            }
                        },
                        model: {
                            fields: {
                                invoice_date: { type: "date" },
                                invoice_received_date: { type: "date" },
                                invoice_due_date: { type: "date" },
                                left: { type:"number" }
                            }
                        }
                    },
                    group: [{ field: "supplier_name"} ],
                }),
            columns:  [
                { template:this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { field: "purchase_order_number", title: "Nomor PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "invoice_number", title: "Nomor Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "supplier_invoice_number", title: "No. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "invoice_date", title: "Tanggal Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "invoice_received_date", title: "Tgl. Penerimaan Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(invoice_received_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "invoice_due_date", title: "Tgl. Jatuh Tempo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= invoice_due_date == null ? '-' : kendo.toString(kendo.parseDate(invoice_due_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "tax_invoice", title: "No. Faktur PPN", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "supplier_name", title: "Supplier", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, groupHeaderTemplate: "Supplier : #= value #" },
                { field: "left", title: "Nominal", width: 200, format: "{0:N}", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        // GridElement.on("change", "#masterCheckBoxFilter", function (e) {
        //     e.preventDefault();
        //     var state= this.checked;
        //     var data = grid._data;
            
        //     for (var i = 0; i < data.length; i++) {
        //         var datarow = data[i]
        //         var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                
        //         if (elementRow != null) {
        //             if (state) {
        //             elementRow.checked = true;
        //             grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
        //             }
        //             else
        //             {
        //             elementRow.checked = false;
        //             grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
        //             }
        //         }
        //      }
        //      var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
        //       if (state && hf_CheckAll_Filter == "true") {
        //             vueComponent.hf_CheckAll_Filter="true";
        //         }
        //         else if (!state && hf_CheckAll_Filter == "true") {
        //             vueComponent.hf_CheckAll_Filter="false";
        //             vueComponent.hf_ID_Filter="";
        //         }
        //         else if (state && hf_CheckAll_Filter == "false") {
        //             vueComponent.hf_CheckAll_Filter= "true";
        //             vueComponent.hf_ID_Filter="";
        //         }
        //         else if (!state)
        //             vueComponent.hf_CheckAll_Filter="false";
        //         else
        //             vueComponent.hf_CheckAll_Filter= "true";
        // });

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            // var dataItem = row.invoice_id.toString() + '|' + row.po_data.customer_data.contact_name.replace(',','') + '|' + row.po_data.project_data.details.cash;
            var dataItem = row.invoice_id.toString() + '|' + row.supplier_id.toString() + '|' + row.currency_id.toString();
                       
            var checkedIdArray = hfIDValue.split(',');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        headerTemplate() {
            // return `<input type="checkbox" id="masterCheckBoxFilter"" />`
            return `&nbsp;`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(',');
            
            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    // var dataItem = datarow.inv_id.toString() + '|' + datarow.po_data.customer_data.contact_name.replace(',','') + '|' + datarow.po_data.project_data.details.cash;
                    var dataItem = datarow.invoice_id.toString() + '|' + datarow.supplier_id.toString() + '|' + datarow.currency_id.toString();

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }
            else {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    // var dataItem = datarow.inv_id.toString() + '|' + datarow.po_data.customer_data.contact_name.replace(',','') + '|' + datarow.po_data.project_data.details.cash;
                    var dataItem = datarow.invoice_id.toString() + '|' + datarow.supplier_id.toString() + '|' + datarow.currency_id.toString();

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
    }
}
</script>
<style scoped>
</style>

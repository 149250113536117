import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PurchasingPaymentInvoiceServices {
  readPurchasingPaymentInvoiceQuery(){
      var query = `
        query($paging:ServerPaging, $status:String){
          GetPurchasingInvoicePaymentV (Paging:$paging, Status:$status) {
            purchasing_invoice_payment {
              created_at
              last_update
              inv_payment_id
              inv_payment_number
              inv_payment_type
              inv_payment_date
              status
              supplier_id
              supplier_name
              currency_id
              currency_code
              currency_name
              payment_method
              bank
              branch
              account_number
              account_name
              round_up
              delivery_fee
              admin_fee
              other_fee
              notes
              total
              purchasing_invoice_payment_detail {
                created_at
                last_update
                inv_payment_id
                invoice_id
                invoice_number
                total
                paid
                total_payment
                left
                notes
              }
            }
            total
          }
        }
      `;
      return query;
  }

  readPayablePurchasingInvoiceQuery(){
      var query = `
        query{
          GetPurchasingPayableInvoiceList  {
            invoice_id
            invoice_number
            invoice_date
            purchase_order_id
            supplier_id
            supplier_name
            notes
            total
            paid
            total_payment
            left
            purchase_order_number
            invoice_received_date
            invoice_due_date
            ppn_tax_percentage
            currency_id
            currency_name
            exchange_rate
            supplier_invoice_number
            tax_invoice
            import_tax
            custom_clearance
            additional_tax
            created_by
          } 
        }
      `;
      return query;
  }

  async getPayableInvoiceQuery(id){
    var array = JSON.parse(id)
    
    const variables = {
      id : array
    }
    
    var query = gql`query($id:[Int]){
      GetPurchasingPayableInvoiceList(InvoiceIds:$id){
          invoice_id
          invoice_number
          tax_invoice
          invoice_date
          purchase_order_id
          supplier_id
          supplier_name
          currency_id
          currency_name
          notes
          total
          paid
          total_payment
          left
        }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    return result.data.GetPurchasingPayableInvoiceList
  }

  async getSinglePayableInvoiceQuery(id){
    var array = JSON.parse(id)
    
    const variables = {
      id : array
    }
    
    var query = gql`query($id:[Int]){
      GetPurchasingPayableInvoiceList(InvoiceIds:$id){
          invoice_id
          invoice_number
          invoice_date
          purchase_order_id
          supplier_id
          supplier_name
          currency_id
          currency_name
          notes
          total
          paid
          total_payment
          left
        }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    
    return result.data.GetPurchasingPayableInvoiceList[0];
  }

  async getPayableInvoiceByIdQuery(id){
    const variables = {
      id : id
    }
    var query = gql`
      query($id:Int){
        GetPurchasingInvoicePaymentV (InvPaymentId: $id) {
          purchasing_invoice_payment {
            created_at
            last_update
            inv_payment_id
            inv_payment_number
            inv_payment_type
            inv_payment_date
            status
            supplier_id
            supplier_name
            currency_id
            currency_code
            currency_name
            payment_method
            bank
            branch
            account_number
            account_name
            round_up
            delivery_fee
            admin_fee
            other_fee
            notes
            purchasing_invoice_payment_detail {
              created_at
              last_update
              inv_payment_id
              invoice_id
              invoice_number
              tax_invoice
              total
              paid
              total_payment
              left
              notes
            }
          }
          total
        }
      }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);

    return result.data.GetPurchasingInvoicePaymentV.purchasing_invoice_payment[0];
  }

  async getBankQuery(){
    var query = gql`query{
      GetBankList{
        id
        bank_name
      }
    }`;
    var result = await globalfunc.defaultApolloQueryDefault(query);
    var data = [];
    for (let i = 0; i < result.data.GetBankList.length; i++) {
        var str = {value:result.data.GetBankList[i].bank_name, label:result.data.GetBankList[i].bank_name}
        data.push(str);
    }

    return data;
  }

  countSisaAmount(data, olddata){
    let arrayObj = [];
    if(data != null){
      for (let i = 0; i < data.length; i++) {
        if(olddata[i].paid > 0){
          var str = { 
            invoice_id: data[i].invoice_id,
            invoice_number: data[i].invoice_number,
            tax_invoice: data[i].tax_invoice,
            invoice_date: data[i].invoice_date,
            notes: data[i].notes,
            total: Math.round(data[i].total),
            paid: Math.round(olddata[i].paid + data[i].left),
            left: Math.round(data[i].left),
            sisa: Math.round(parseFloat(data[i].total-olddata[i].paid)-data[i].left),
          }
          arrayObj.push(str)
        }
        else{
          var str = { 
            invoice_id: data[i].invoice_id,
            invoice_number: data[i].invoice_number,
            tax_invoice: data[i].tax_invoice,
            invoice_date: data[i].invoice_date,
            notes: data[i].notes,
            total: Math.round(data[i].total),
            paid: Math.round(data[i].left),
            left: Math.round(data[i].left),
            sisa: Math.round(parseFloat(data[i].total).toFixed(2)-data[i].left),
          }
          arrayObj.push(str)
        }
      }
    }
    
    return arrayObj;
  }

  countSisaAmountEdit(array){
    let arrayObj = [];
    if(array != null){
      for (let i = 0; i < array.length; i++) {
        var str = { 
          invoice_id: array[i].invoice_id,
          invoice_number: array[i].invoice_number,
          tax_invoice: array[i].tax_invoice,
          invoice_date: array[i].invoice_date,
          notes: array[i].notes,
          total: Math.round(array[i].total),
          paid: Math.round(array[i].paid),
          left: Math.round(array[i].total_payment),
          sisa: Math.round(parseFloat(array[i].total - array[i].paid).toFixed(2)),
        }
        arrayObj.push(str)
      }
    }
    
    return arrayObj;
  }

  validateDuplicateSelectedData(data){
    var array = data.split(',');

    let arrayObj = [];
    for (let i = 0; i < array.length; i++) {
      var detailArray = array[i].split('|');
      var obj = 
        {
          id : detailArray[0],
          supplier_id : detailArray[1],
          currency_id : detailArray[2]
        }
      arrayObj.push(obj)
    }
    
    var unique = arrayObj.map(e => e['supplier_id'] && e['currency_id'] ).map((e, i, final) => final.indexOf(e) === i && i).filter(obj=> arrayObj[obj]);

    if(unique.length > 1){
      return "not match";
    }
    else{
      var id = arrayObj.map(e => parseInt(e['id']));
      return id;
    }
  }

  async addQuery(variables){
    var query = gql`
      mutation($data:NewPurchasingInvoicePayment!){
        CreatePurchasingInvoicePayment(New:$data)
      }
    `;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }

  async editQuery(variables){
    var query = gql`
      mutation ( $id:Int!, $data:NewPurchasingInvoicePayment! ) {
        UpdatePurchasingInvoicePayment ( ID:$id, New:$data )
      }
    `;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }
}

export default new PurchasingPaymentInvoiceServices();

<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import purchasingPaymentInvoiceServices from '../Script/PurchasingPaymentInvoiceServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurchasingPaymentInvoiceGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "status", new_field: "\"purchasing_invoice_payment\".\"status\""},
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { query: purchasingPaymentInvoiceServices.readPurchasingPaymentInvoiceQuery(),
                                        variables: variables};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingInvoicePaymentV == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingInvoicePaymentV.purchasing_invoice_payment;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingInvoicePaymentV == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingInvoicePaymentV.total;
                        }
                    },
                    model: {
                        fields: {
                            inv_payment_date: { type: "date" },
                            created_at: { type: "date" },
                            total: {type: "number" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "inv_payment_number", title: "No. Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "inv_payment_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: "#= kendo.toString(kendo.parseDate(inv_payment_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "total", title: "Total", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, format: "{0:N}" },
                { field: "payment_method", title: "Payment Method", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "bank", title: "Bank", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "account_name", title: "Nama Akun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.inv_payment_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.inv_payment_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.inv_payment_id, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const invocieData = {
                inv_payment_id : dataItem.inv_payment_id,
                inv_payment_number : dataItem.inv_payment_number,
                status : dataItem.status
            }
            
            statusClick(invocieData);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithStatusChangesButton("Purchasing Payment Invoice")
        },
        detailInit: async function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.purchasing_invoice_payment_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'invoice_number', title: "Invoice No", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'total', title: "Nilai Invoice", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                    { field: 'paid', title: "Terbayar", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                    { field: 'total_payment', title: "Jumlah Bayar", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                    { field: 'left', title: "Sisa", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true, attributes: { "class": "k-text-right" }, format:"{0:N0}" },
                    { field: 'notes', title: "Catatan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },  editable: false, nullable: true },
                ],
            });
        },
        changeStatus(status){
            this.dataSource=  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "status", new_field: "\"purchasing_invoice_payment\".\"status\""},
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { query: purchasingPaymentInvoiceServices.readPurchasingPaymentInvoiceQuery(),
                                        variables: variables};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingInvoicePaymentV.purchasing_invoice_payment == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingInvoicePaymentV.purchasing_invoice_payment;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingInvoicePaymentV.total == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingInvoicePaymentV.total;
                        }
                    },
                    model: {
                        fields: {
                            inv_payment_date: { type: "date" },
                            created_at: { type: "date" },
                            total: {type: "number" },
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            });
        },
    }
}
</script>